<template>
  <div class="document-page">
    <section>
      <div class="row">
        <!-- Document Details -->
        <div class="col-12">
          <div v-if="document.deleted_at" class="document-deleted-banner">This document has been deleted.</div>
          <b-card>
            <section>
              <div class="row mt-2">
                <div class="col-md-6">
                  <h4 class="mb-1">Document Details</h4>

                  <table class="table table-striped">
                    <tbody>
                      <tr><td class="pr-1">Title</td><td>{{ document.title }}</td></tr>
                      <tr><td class="pr-1">Created On</td><td>{{ document.created_at | formatDateTime }}</td></tr>
                      <tr><td class="pr-1">Last Opened</td><td>{{ document.last_opened | formatDateTime }}</td></tr>
                      <tr><td v-if="document.deleted_at" class="pr-1">Deleted On</td><td>{{ document.deleted_at | formatDateTime }}</td></tr>
                    </tbody>
                  </table>
                </div>
                <div class="col-md-6">
                  <h4 class="mb-1">Revision Details</h4>
                  <table class="table table-striped">
                    <tbody>
                      <tr><td class="pr-1">Total Revisions</td><td>{{ total_count }}</td></tr>
                      <tr><td class="pr-1">Latest Revision Date</td><td>{{ document.updated_at | formatDateTime }}</td></tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </section>
          </b-card>
        </div>

        <!-- Revision Details -->
        <div class="col-9">
          <!-- Document -->
          <b-card class="h-100" style="min-height: 50vh">
            <div class="d-flex justify-content-between mb-2">
              <div class=" border-bottom">Title: {{ revision.title }}</div>
              <div class="d-flex">
                <div class=" border-bottom mr-2">Created: {{ revision.created_at | formatDateTime }}</div>
                <div class=" border-bottom">Revision: {{ revision.current_revision }}</div>
              </div>
            </div>
            <div class="ck-content table-responsive" v-html="revision.content" />
          </b-card>
        </div>

        <!-- Revisions -->
        <div class="col-3">
          <b-card v-if="!document.deleted_at">
            <p class="font-weight-bolder">Actions</p>
            <b-btn variant="danger"
                   class="full-width mb-1"
                   @click="showDeleteModal"
            >
              Delete
            </b-btn>
          </b-card>

          <b-card>
            <p class="font-weight-bolder">Revisions</p>
            <b-table
              responsive
              class="data-table"
              hover
              :items="revisions"
              :fields="headers"
              :current-page="currentPage"
              :per-page="0"
              :tbody-tr-class="revisionTableRowClass"
              @row-clicked="handleSelectRevision"
            >

              <template #cell(revision_number)="data">
                {{ data.item.revision_number }}
              </template>

              <template #cell(created_at)="data">
                {{ data.item.created_at | formatDate }}
              </template>

            </b-table>

            <b-row>
              <b-col v-if="total_count > filters.page_size" md="6" class="my-1">
                <b-pagination v-model="currentPage" :total-rows="total_count" :per-page="filters.page_size" class="my-0" @change="listMoreRevisions" />
              </b-col>
              <p v-if="total_count === 0" class="col-6 ml-2 my-1">No records</p>
            </b-row>

          </b-card>
        </div>
      </div>
    </section>

    <b-modal id="modal-delete-document" title="Delete a document" hide-footer>
      <delete-modal
        v-if="document"
        :subtitle="document.title"
        title="Are you sure you wish to delete this document? This action cannot be undone."
        @delete="deleteDoc"
        @close="closeDeleteModal"
      />
    </b-modal>
  </div>
</template>
<script>
import 'vue-search-select/dist/VueSearchSelect.css';
import helperService from "@/services/HelperService";
import PurpleDocsDocumentService from "@/services/PurpleDocsDocumentService";
import DeleteModal from '@/components/modals/DeleteModal';

export default {
  name: 'PurpleDocDocument',
  components: {DeleteModal},
  data() {
    return {
      revisions: [],
      document: {
        title: "",
        content: "",
        created_at: "",
        updated_at: "",
        last_opened: "",
        current_revision: "",
      },
      revision: {
        title: "",
        content: "",
        current_revision: "",
      },
      currentPage: 1,
      total_count: 0,
      filters: {
        page: 0,
        page_size: 25,
      },
      headers: [
        {
          key: 'revision_number',
          label: 'Revision',
        },
        {
          key: 'created_at',
          label: 'Created',
          class: 'text-right',
        },
      ],
    };
  },
  mounted() {
    this.getDocument()
    this.listRevisions()
  },
  methods: {
    async getDocument() {
      try {
        const { data } = await PurpleDocsDocumentService.getDocument(this.$route.params.id)
        this.document = data
        this.revision = data
      } catch (err) {
        helperService.showNotfyErr(this.$toast, err, "An error occurred fetching the document, please refresh and try again.")
      }
    },
    async getRevision(revisionNumber) {
      try {
        const { data } = await PurpleDocsDocumentService.getDocument(this.$route.params.id, { revision: revisionNumber })
        this.revision = data
      } catch (err) {
        helperService.showNotfyErr(this.$toast, err, "An error occurred fetching the document, please refresh and try again.")
      }
    },
    handleSelectRevision(item) {
      this.getRevision(item.revision_number)
    },
    revisionTableRowClass(item, type) {
      if (!item || type !== 'row') return
      if (item.revision_number === this.revision.current_revision) return 'revision-selected-row bg-primary'
    },
    async listRevisions() {
      try {
        const { data } = await PurpleDocsDocumentService.listDocumentRevisions(this.$route.params.id, this.filters)
        this.revisions = data.data
        this.filters.page = data.meta.page
        this.filters.page_size = data.meta.page_size
        this.total_count = data.meta.total_count;
      } catch (err) {
        helperService.showNotfyErr(this.$toast, err, "An error occurred fetching the document, please refresh and try again.")
      }
    },
    listMoreRevisions(page) {
      this.filters.page = page - 1 // api index starts at 0, bootstrap starts at 1 lol
      this.listRevisions()
    },
    showDeleteModal() {
      this.$bvModal.show('modal-delete-document')
    },
    closeDeleteModal() {
      this.$bvModal.hide('modal-delete-document')
    },
    async deleteDoc() {
      // Delete current doc
      try {
        await PurpleDocsDocumentService.deleteDocument(this.$route.params.id)
        helperService.showNotfySuccess(this.$toast, `The document has been successfully deleted.`)
        // Reload doc
        await this.getDocument()
      } catch(err) {
        helperService.showNotfyErr(this.$toast, err, 'An error occurred while deleting the document. Please try again.')
      } finally {
        this.closeDeleteModal()
      }
    }
  }
};
</script>

<style lang="scss">
.document-deleted-banner {
  background-color: #ff4b5c;
  color: #ffffff;
  text-align: center;
  font-weight: 600;
  padding: 5px 0;
  border-radius: 5px 5px 0 0;
}
.revision-selected-row {
  &:hover {
    color: white !important;
  }
  color: white;
  font-weight: bold;
}
</style>
